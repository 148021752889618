export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';

export const UPDATE_PRODUCT_BEGIN = 'UPDATE_PRODUCT_BEGIN';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';

export const FETCH_GLOBALS_BEGIN = 'FETCH_GLOBALS_BEGIN';
export const FETCH_GLOBALS_ERROR = 'FETCH_GLOBALS_ERROR';
export const FETCH_GLOBALS_SUCCESS = 'FETCH_GLOBALS_SUCCESS';

export const UPDATE_GLOBALS_BEGIN = 'UPDATE_GLOBALS_BEGIN';
export const UPDATE_GLOBALS_ERROR = 'UPDATE_GLOBALS_ERROR';
export const UPDATE_GLOBALS_SUCCESS = 'UPDATE_GLOBALS_SUCCESS';

export const UPDATE_MARKETPLACE_BEGIN = 'UPDATE_MARKETPLACE_BEGIN';
export const UPDATE_MARKETPLACE_ERROR = 'UPDATE_MARKETPLACE_ERROR';
export const UPDATE_MARKETPLACE_SUCCESS = 'UPDATE_MARKETPLACE_SUCCESS';

export const FETCH_MARKETPLACE_BEGIN = 'FETCH_MARKETPLACE_BEGIN';
export const FETCH_MARKETPLACE_ERROR = 'FETCH_MARKETPLACE_ERROR';
export const FETCH_MARKETPLACE_SUCCESS = 'FETCH_MARKETPLACE_SUCCESS';

export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_ERROR = 'FETCH_REPORTS_ERROR';

export const FETCH_INVENTORY_REPORT_SUCCESS = 'FETCH_INVENTORY_REPORT_SUCCESS';
export const FETCH_INVENTORY_REPORT_ERROR = 'FETCH_INVENTORY_REPORT_ERROR';
export const FETCH_INVENTORY_REPORT_COUNTRIES_SUCCESS = 'FETCH_INVENTORY_COUNTRIES_REPORT_SUCCESS';
export const FETCH_INVENTORY_REPORT_COUNTRIES_ERROR = 'FETCH_INVENTORY_COUNTRIES_REPORT_ERROR';
export const FETCH_INVENTORY_REPORT_SL_SUCCESS = 'FETCH_INVENTORY_REPORT_SL_SUCCESS';
export const FETCH_INVENTORY_REPORT_SL_ERROR = 'FETCH_INVENTORY_REPORT_SL_ERROR';