import {FETCH_REPORTS_SUCCESS, FETCH_REPORTS_ERROR} from '../actions/types';

const initalState = {
    reports: null
};

export default function(state = initalState, action){
    switch(action.type){
        case FETCH_REPORTS_SUCCESS:
            return ({
                ...state,
                reports: action.payload
            });
        case FETCH_REPORTS_ERROR:
            return ({
                ...state
            });
        default:
            return state;
    }
}