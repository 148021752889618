import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse/index';
import {pagesConfigs} from 'main/content/pages/pagesConfigs';
import { LoginConfig } from 'main/content/login/LoginConfig';
import { DashboardConfig } from 'main/content/dashboard/DashboardConfig';
import { SettingsConfig } from 'main/content/settings/SettingsConfig';
import {ChangelogConfig} from "../main/content/changelog/ChangelogConfig";
import {FileManagerAppConfig} from "../main/content/file-manager/FileManagerAppConfig";
import _ from '@lodash';
import {authRoles} from 'auth';
import {InventoryConfig} from "../main/content/inventory/InventoryConfig";
import {InventoryUKConfig} from "../main/content/inventoryuk/InventoryUKConfig";


function setUserAuth(configs)
{
    return configs.map(config => _.merge({}, config, {auth: authRoles.user}))
}

const routeConfigs = [
    ...setUserAuth([
        DashboardConfig,
        SettingsConfig,
        FileManagerAppConfig,
        ChangelogConfig,
        InventoryConfig,
        InventoryUKConfig
    ]),
    ...pagesConfigs,
    LoginConfig
];

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Redirect to="/dashboard" />
    },
    {
        exact    : true,
        component: () => <Redirect to="/pages/errors/error-404" />
    }
];
