import {
    FETCH_INVENTORY_REPORT_SUCCESS,
    FETCH_INVENTORY_REPORT_ERROR,
    FETCH_INVENTORY_REPORT_COUNTRIES_SUCCESS,
    FETCH_INVENTORY_REPORT_COUNTRIES_ERROR,
    FETCH_INVENTORY_REPORT_SL_ERROR, FETCH_INVENTORY_REPORT_SL_SUCCESS
} from '../actions/types';

const initalState = {
    fetchedData: false,
    data: null,
    fetchedCountryData:false,
    countryData:null,
    fetchedSL: false,
    slData:null
};

export default function(state = initalState, action){
    switch(action.type){
        case FETCH_INVENTORY_REPORT_SUCCESS:
            return ({
                ...state,
                fetchedData: true,
                data: action.payload
            });
        case FETCH_INVENTORY_REPORT_ERROR:
            return ({
                ...state,
                fetchedData: false,
            });
        case FETCH_INVENTORY_REPORT_COUNTRIES_SUCCESS:
            return ({
                ...state,
                fetchedCountryData: true,
                countryData: action.payload
            });
        case FETCH_INVENTORY_REPORT_COUNTRIES_ERROR:
            return ({
                ...state,
                fetchedCountryData: false,
            });
        case FETCH_INVENTORY_REPORT_SL_SUCCESS:
            return ({
                ...state,
                fetchedSL: true,
                slData: action.payload
            });
        case FETCH_INVENTORY_REPORT_SL_ERROR:
            return ({
                ...state,
                fetchedSL: false,
            });
        default:
            return state;
    }
}