import { combineReducers } from 'redux';
import products from './product.reducer';
import globals from './global.reducer';
import marketplaces from './marketplace.reducer';
import reports from './reports.reducer';
import inventory from './inventory.reducer';

const squirrelReducers = combineReducers({
    products: products,
    globals: globals,
    marketplaces: marketplaces,
    reports: reports,
    inventory: inventory
});

export default squirrelReducers;