import axios from 'axios/index';
import {generateKey, signToken} from "../../../../../api/helpers/crypto/functions/kgs";

export const GET_INVENTORY_REPORT = '[INVENTORY] GET REPORT';

export function getInventory()
{
    const kp = generateKey();
    const signedToken = signToken(kp.publicKey, 5000);

    const request = axios.get(
        process.env.REACT_APP_API_BASE_URL_V2+'inventory',
        {
            headers: {
                "x-access-token": signedToken
            }
        }
    )

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_INVENTORY_REPORT,
                payload: response.data
            })
        );
}
