import * as Actions from '../actions';


export default function (state = {}, action) {
    switch ( action.type )
    {
        case Actions.GET_INVENTORY_REPORT:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};