import React, { Component } from 'react';
/*import { SvgIcon } from '@material-ui/core';*/
import { withStyles } from '@material-ui/core/styles/index';
import StorageIcon from '@material-ui/icons/Storage';
import Axios from 'axios';

const styles = theme => ({
    root: {}
});

class MainFooter extends Component {
    state = {
        dbStatus: null,
        serverStatus: null,
        timestamp: null,
    }

    componentWillMount() {
        const self = this;
        Axios.get("https://api2.rubberneck.cloud/").then(
            (res) => {
                self.setState({
                    dbStatus: res.data.dbStatus,
                    timestamp: res.data.timestamp
                });
            }
        ).catch(
            (err) => {

            });
    }

    render() {
        /*let db;
        let server;
        if (this.state.dbStatus !== null && this.state.dbStatus !== "DOWN") {
            db = <SvgIcon><path fill="#00cc33" d="M17.75,22.16L15,19.16L16.16,18L17.75,19.59L21.34,16L22.5,17.41L17.75,22.16M12,3C16.42,3 20,4.79 20,7C20,9.21 16.42,11 12,11C7.58,11 4,9.21 4,7C4,4.79 7.58,3 12,3M4,9C4,11.21 7.58,13 12,13C16.42,13 20,11.21 20,9V12C20,12.43 19.87,12.84 19.62,13.22C19.1,13.08 18.56,13 18,13C15.79,13 13.85,14.2 12.81,16H12C7.58,16 4,14.21 4,12V9M4,14C4,16.21 7.58,18 12.08,18L12,19C12,19.7 12.12,20.37 12.34,21H12C7.58,21 4,19.21 4,17V14Z" /></SvgIcon>
        } else {
            db = <SvgIcon><path fill="#cc0033" d="M15.46,15.88L16.88,14.46L19,16.59L21.12,14.47L22.53,15.88L20.41,18L22.54,20.12L21.12,21.54L19,19.41L16.88,21.53L15.47,20.12L17.59,18L15.46,15.88M12,3C16.42,3 20,4.79 20,7C20,9.21 16.42,11 12,11C7.58,11 4,9.21 4,7C4,4.79 7.58,3 12,3M4,9C4,11.21 7.58,13 12,13C16.42,13 20,11.21 20,9V12.08L19,12C16.41,12 14.2,13.64 13.36,15.94L12,16C7.58,16 4,14.21 4,12V9M4,14C4,16.21 7.58,18 12,18H13C13,19.05 13.27,20.04 13.75,20.9L12,21C7.58,21 4,19.21 4,17V14Z" /></SvgIcon>
        }

        if (this.state.serverStatus !== null && this.state.serverStatus !== "DOWN") {
            server = <SvgIcon><path fill="#00cc33" d="M4,1H20A1,1 0 0,1 21,2V6A1,1 0 0,1 20,7H4A1,1 0 0,1 3,6V2A1,1 0 0,1 4,1M4,9H20A1,1 0 0,1 21,10V14A1,1 0 0,1 20,15H4A1,1 0 0,1 3,14V10A1,1 0 0,1 4,9M4,17H20A1,1 0 0,1 21,18V22A1,1 0 0,1 20,23H4A1,1 0 0,1 3,22V18A1,1 0 0,1 4,17M9,5H10V3H9V5M9,13H10V11H9V13M9,21H10V19H9V21M5,3V5H7V3H5M5,11V13H7V11H5M5,19V21H7V19H5Z" /></SvgIcon>
        } else {
            server = <SvgIcon><path fill="#cc0033" d="M4,1H20A1,1 0 0,1 21,2V6A1,1 0 0,1 20,7H8.82L6.82,5H7V3H5V3.18L3.21,1.39C3.39,1.15 3.68,1 4,1M22,22.72L20.73,24L19.73,23H4A1,1 0 0,1 3,22V18A1,1 0 0,1 4,17H13.73L11.73,15H4A1,1 0 0,1 3,14V10A1,1 0 0,1 4,9H5.73L3.68,6.95C3.38,6.85 3.15,6.62 3.05,6.32L1,4.27L2.28,3L22,22.72M20,9A1,1 0 0,1 21,10V14A1,1 0 0,1 20,15H16.82L10.82,9H20M20,17A1,1 0 0,1 21,18V19.18L18.82,17H20M9,5H10V3H9V5M9,13H9.73L9,12.27V13M9,21H10V19H9V21M5,11V13H7V11H5M5,19V21H7V19H5Z" /></SvgIcon>
        }
*/
        let color_db = 'red';

        if (this.state.dbStatus === "Connected") {
            color_db = 'green';
        }

        return (
            <div className="flex flex-1 items-center">
                <div className="text-left"><StorageIcon style={{ color: color_db }} /></div>
                <div className="text-right w-full">v.{process.env.REACT_APP_VERSION}</div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MainFooter);