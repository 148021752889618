import {FETCH_GLOBALS_SUCCESS, FETCH_GLOBALS_ERROR} from '../actions/types';

const initalState = {
    fetchedGlobals: false,
    data: null
};

export default function(state = initalState, action){
    switch(action.type){
        case FETCH_GLOBALS_SUCCESS:
            return ({
                ...state,
                fetchedGlobals: true,
                data: action.payload
            });
        case FETCH_GLOBALS_ERROR:
            return ({
                ...state,
                fetchedGlobals: false,
            });
        default:
            return state;
    }
}