//Generate Key
import nacl from 'tweetnacl';
import scrypt from "scryptsy";
import jwt from "jsonwebtoken";
import util from "tweetnacl-util";

let salt = nacl.randomBytes(16);
let N = 16384; // number of iterations
let r = 8; // Memory factor
let p = 1; // Parallelization factor
/**
 * @returns {*} key pair
 */
export const generateKey = () => {
    let key = scrypt(process.env.REACT_APP_SECRET_KEY_SQUIRREL_API, salt.toString('base64'), N, r, p, nacl.secretbox.keyLength);
    return nacl.box.keyPair.fromSecretKey(key);
};

export const signToken = (pubkey, expiresIn) => {
    return jwt.sign({ key: util.encodeBase64(pubkey) }, process.env.REACT_APP_S, { expiresIn: expiresIn });
};