import {combineReducers} from 'redux';
import fuse from './fuse';
import auth from 'auth/store/reducers';
import squirrelApi from 'api/store/reducers';
import reports from 'main/content/file-manager/store/reducers';
import inventory from 'main/content/inventory/store/reducers';

import quickPanel from 'main/quickPanel/store/reducers';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        quickPanel,
        squirrelApi,
        reports,
        inventory,
        ...asyncReducers
    });

export default createReducer;
