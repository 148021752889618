import axios from 'axios/index';
import {generateKey, signToken} from "../../../../../api/helpers/crypto/functions/kgs";

export const GET_FILES = '[FILE MANAGER APP] GET FILES';

export function getFiles() {
    const kp = generateKey();
    const signedToken = signToken(kp.publicKey, 5000);
    const request = axios.get(`${process.env.REACT_APP_API_BASE_URL_V2}reports/`,{
    headers: {
        'x-access-token': signedToken
    }
});

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_FILES,
                payload: response.data
            })
        );
}
