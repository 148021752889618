import {FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_ERROR, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_ERROR} from '../actions/types';

const initalState = {
    fetchedData: false,
    data: null,
    updateStatus: null,
};

export default function(state = initalState, action){
    switch(action.type){
        case FETCH_PRODUCTS_SUCCESS:
            return ({
                ...state,
                fetchedData: true,
                data: action.payload
            });
        case FETCH_PRODUCTS_ERROR:
            return ({
                ...state,
                fetchedData: false,
            });
        case UPDATE_PRODUCT_SUCCESS:
            return({
                ...state,
                updateStatus: action.payload
            });
        case UPDATE_PRODUCT_ERROR:
            return ({
                ...state,
                updateStatus: action.payload
            });
        default:
            return state;
    }
}