export const fuseNavigationConfig = [
    {
        'id': 'applications',
        'title': 'Pages',
        'type': 'group',
        'icon': 'apps',
        'children': [
            {
                'id': 'dashboard',
                'title': 'Dashboard',
                'type': 'item',
                'icon': 'dashboard',
                'url': '/dashboard'
            },
            {
                'id': 'inventory',
                'title': 'Inventory',
                'type': 'item',
                'icon': 'receipt',
                'url': '/inventory'
            },
           /* {
                'id': 'inventoryuk',
                'title': 'Inventory UK',
                'type': 'item',
                'icon': 'receipt',
                'url': '/inventory-uk'
            },*/
            {
                'id': 'reports',
                'title': 'Reports',
                'type': 'item',
                'icon': 'folder',
                'url': '/reports'
            },
           /* {
                'id': 'settings-component',
                'title': 'Settings',
                'type': 'item',
                'icon': 'settings',
                'url': '/settings'
            },*/
            {
                'id': 'changelog-component',
                'title': 'Changelog',
                'type': 'item',
                'icon': 'assignment',
                'url': '/changelog'
            },
        ]
    }
];