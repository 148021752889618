import { UPDATE_MARKETPLACE_SUCCESS, UPDATE_MARKETPLACE_ERROR, FETCH_MARKETPLACE_ERROR, FETCH_MARKETPLACE_SUCCESS } from '../actions/types';

const initalState = {
    status: null,
    data: null,
    fetchedData: false
};

export default function (state = initalState, action) {
    switch (action.type) {
        case UPDATE_MARKETPLACE_SUCCESS:
            return ({
                ...state,
                status: action.payload
            });
        case UPDATE_MARKETPLACE_ERROR:
            return ({
                ...state,
                status: action.payload,
            });
        case FETCH_MARKETPLACE_SUCCESS:
            return ({
                ...state,
                data: action.payload,
                fetchedData: true
            });
        case FETCH_MARKETPLACE_ERROR:
            return ({
                ...state,
                data: action.payload,
                fetchedData: false
            })
        default:
            return state;
    }
}